<template>
  <div v-if="form">
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0 mb-3">
        <b-col sm="6" class="text-center text-sm-left">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายละเอียดลูกค้าบริษัท
          </h1>
          <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->
        </b-col>
        <!-- <b-col sm="6" class="text-center text-sm-right">
          <span class="label-text mr-3 txt-relative">สถานะการแสดงผล</span>
          <b-form-checkbox
            switch
            size="lg"
            class="d-inline-block mb-1"
            @change="onChangeEnableAffiliate"
            :value="true"
            :unchecked-value="false"
            v-model="form.user.enabled"
            ><span class="ml-2 main-label">{{
              form.user.enabled ? "เปิดใช้งาน" : "ระงับบัญชี"
            }}</span></b-form-checkbox
          >
        </b-col> -->
      </CRow>

      <div>
        <b-row class="pb-sm-1 position-relative mx-3 mx-sm-0 mb-3">
          <b-col sm="12" class="bg-white py-3">
            <b-row>
              <b-col sm="4" class="text-center">
                <div>
                  <div
                    :style="{
                      'background-image': 'url(' + imageProfile + ')',
                    }"
                    class="preview-box border-0 mb-2 affiliate-img"
                  ></div>
                  <span
                    v-if="!form.user.enabled"
                    class="seller-status status-red"
                    >ปิดใช้งาน</span
                  >
                  <span
                    v-else-if="form.user.enabled"
                    class="seller-status status-green"
                  >
                    เปิดใช้งาน
                  </span>
                  <span v-else class="seller-status status-yellow">
                    รอการยืนยัน
                  </span>
                  <p class="f-12 m-0 mt-2">
                    เป็นสมาชิกวันที่
                    {{ new Date(form.user.createdTime) | moment($formatDate) }}
                  </p>
                </div>
              </b-col>
              <b-col sm="8">
                <b-row class="align-items-start">
                  <b-col sm="12">
                    <p class="text-right">
                      <span class="font-weight-bold">สถานะ:</span>
                      {{ form.user.approveStatusName }}
                    </p>
                    <InputText
                      textFloat="ชื่อบัญชีใช้งาน"
                      placeholder="ชื่อบัญชีใช้งาน"
                      type="text"
                      name="companyName"
                      v-model="form.user.companyName"
                      isRequired
                      :isValidate="$v.form.user.companyName.$error"
                      :v="$v.form.user.companyName"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="เบอร์ติดต่อ"
                      placeholder="เบอร์ติดต่อ"
                      type="text"
                      name="telephone"
                      v-model="form.user.telephone"
                      isRequired
                      :isValidate="$v.form.user.telephone.$error"
                      :v="$v.form.user.telephone"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="อีเมล"
                      placeholder="อีเมล"
                      type="email"
                      name="email"
                      v-model="form.user.email"
                      isRequired
                      :isValidate="$v.form.user.email.$error"
                      :v="$v.form.user.email"
                    />
                  </b-col>
                  <b-col sm="6">
                    <label class="font-weight-bold">ข้อกำหนดและเงื่อนไข</label>
                    <b-form-group class="m-auto">
                      <b-form-radio-group v-model="form.user.isConsent">
                        <b-form-radio :value="1">ยินยอม</b-form-radio>
                        <b-form-radio :value="0">ไม่ยินยอม</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <label class="font-weight-bold">PDPA</label>
                    <b-form-group class="m-auto">
                      <b-form-radio-group v-model="form.user.approveConsent">
                        <b-form-radio :value="1">ยินยอม</b-form-radio>
                        <b-form-radio :value="0">ไม่ยินยอม</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <label class="font-weight-bold mt-3"
                      >วัตถุประสงค์ <span class="text-danger">*</span></label
                    >
                    <p
                      class="text-danger"
                      v-if="$v.form.user.userTargetTypeId.$error"
                    >
                      กรุณาเลือกข้อมูล
                    </p>
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        id="objective"
                        :aria-describedby="ariaDescribedby"
                        v-model="form.user.userTargetTypeId"
                      >
                        <b-form-checkbox
                          v-for="(type, index) of list.userTargetType"
                          v-bind:key="index"
                          :value="type.targetTypeId"
                          class="checkbox-main"
                        >
                          {{ type.name }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <label class="font-weight-bold mt-3"
                      >Tier ของลูกค้า <span class="text-danger">*</span></label
                    >
                    <InputSelect
                      title=""
                      text="ประเภท Tier"
                      name="tierType"
                      valueField="id"
                      textField="name"
                      v-bind:options="tier"
                      v-model="form.user.tierId"
                      v-on:onDataChange="handleChangeTierType"
                      ref="tierType"
                    />
                  </b-col>
                  <b-col sm="6">
                    <span
                      class="f-14 text-blue text-underline pointer"
                      @click="resetPassword"
                    >
                      รีเซ็ทรหัสผ่าน
                    </span>
                  </b-col>
                  <b-col md="12" v-if="form.user.statusId == 2" class="mt-3">
                    <p>
                      <span class="font-weight-bold">เหตุผลในการปฏิเสธ: </span>
                      {{ form.user.note }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col sm="6" class="bg-white py-3">
            <div class="row h-100">
              <b-col class="m-auto">
                <b-row class="">
                  <b-col cols="5" class="font-weight-bold">เลขที่บัญชี :</b-col>
                  <b-col class="mb-2 mb-sm-0" cols="7">
                    {{ form.bankAccount.accountNo }}
                  </b-col>
                </b-row>

                <b-row class="">
                  <b-col cols="5" class="font-weight-bold">ธนาคาร :</b-col>
                  <b-col class="mb-2 mb-sm-0" cols="7">
                    {{ form.bankAccount.bankName }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <div
                      class="text-center text-primary text-underline f-16 mt-4"
                    >
                      <router-link :to="'/affiliate/bankaccount/details/' + id">
                        <span class="mr-1">ดูเอกสารบัญชี</span>
                      </router-link>
                      <router-link :to="'/affiliate/log/' + id">
                        <span class="mr-1">ดูประวัติ</span>
                      </router-link>
                      <router-link :to="'/affiliate/follower/' + id">
                        <span class="mr-1">ดูรายการผู้ติดตาม</span>
                      </router-link>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col> -->
        </b-row>
        <b-row class="my-3 mx-0">
          <b-col class="pl-md-0 pr-md-1 px-0 mb-2 mb-md-0">
            <div class="bg-white p-3 h-100">
              <b-row>
                <b-col>
                  <p class="font-weight-bold">รายละเอียดเพิ่มเติม</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputSelect
                    title="ประเภทธุรกิจ"
                    text="ประเภทธุรกิจ"
                    name="businessType"
                    valueField="businessTypeId"
                    textField="name"
                    v-bind:options="list.userBusinessType"
                    v-model="form.user.businessTypeId"
                    v-on:onDataChange="handleChangeBusinessType"
                    isRequired
                    ref="businessType"
                    v-bind:isValidate="$v.form.user.businessTypeId.$error"
                    :v="$v.form.user.businessTypeId"
                  />
                </b-col>
                <b-col md="6">
                  <InputSelect
                    title="ประเภทการจดทะเบียน"
                    text="ประเภทการจดทะเบียน"
                    name="registrationType"
                    valueField="registrationTypeId"
                    textField="name"
                    v-bind:options="list.userRegistrationType"
                    v-model="form.user.registrationTypeId"
                    v-on:onDataChange="handleChangeRegistrationType"
                    isRequired
                    ref="registrationType"
                    :isValidate="$v.form.user.registrationTypeId.$error"
                    :v="$v.form.user.registrationTypeId"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.user.taxId"
                    textFloat="เลขประจำตัวผู้เสียภาษี"
                    placeholder="เลขประจำตัวผู้เสียภาษี"
                    type="text"
                    name="taxID"
                    isRequired
                    :isValidate="$v.form.user.taxId.$error"
                    :v="$v.form.user.taxId"
                    :maxlength="13"
                    @onkeypress="isNumber"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.user.businessName"
                    textFloat="ชื่อบริษัท/ห้างร้าน"
                    placeholder="ชื่อบริษัท/ห้างร้าน"
                    type="text"
                    name="companyStoreName"
                    isRequired
                    :isValidate="$v.form.user.businessName.$error"
                    :v="$v.form.user.businessName"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.user.branchName"
                    textFloat="ชื่อสาขา"
                    placeholder="ชื่อสาขา"
                    type="text"
                    name="branchName"
                    :isValidate="$v.form.user.branchName.$error"
                    :v="$v.form.user.branchName"
                    :isRequired="form.user.registrationTypeId != 4"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.user.branchNo"
                    textFloat="เลขที่สาขา"
                    placeholder="เลขที่สาขา"
                    type="text"
                    name="branchNumber"
                    :isValidate="$v.form.user.branchNo.$error"
                    :v="$v.form.user.branchNo"
                    :maxlength="5"
                    @onkeypress="isNumber"
                    :isRequired="form.user.registrationTypeId != 4"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.user.address"
                    textFloat="บ้านเลขที่"
                    placeholder="บ้านเลขที่"
                    type="text"
                    name="addressNo"
                    isRequired
                    :isValidate="$v.form.user.address.$error"
                    :v="$v.form.user.address"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.user.building"
                    textFloat="หมู่บ้าน / อาคาร"
                    placeholder="หมู่บ้าน / อาคาร"
                    type="text"
                    name="buildingVillage"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.user.alley"
                    textFloat="ซอย"
                    placeholder="ซอย"
                    type="text"
                    name="alley"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.user.road"
                    textFloat="ถนน"
                    placeholder="ถนน"
                    type="text"
                    name="road"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputSelect
                    title="จังหวัด"
                    text="จังหวัด"
                    name="provinceId"
                    valueField="id"
                    textField="name"
                    v-bind:options="list.provinces"
                    v-model="form.user.provinceId"
                    v-on:onDataChange="handleChangeProvince"
                    isRequired
                    ref="provinceId"
                    :isValidate="$v.form.user.provinceId.$error"
                    :v="$v.form.user.provinceId"
                  />
                </b-col>
                <b-col md="6">
                  <InputSelect
                    title="เขต/อำเภอ"
                    text="เขต/อำเภอ"
                    name="districtId"
                    valueField="id"
                    textField="name"
                    v-bind:options="list.districts"
                    v-model="form.user.districtId"
                    v-on:onDataChange="handleChangeDistrict"
                    isRequired
                    ref="districtId"
                    :isValidate="$v.form.user.districtId.$error"
                    :v="$v.form.user.districtId"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <InputSelect
                    title="แขวง/ตำบล"
                    text="แขวง/ตำบล"
                    name="subdistrictId"
                    valueField="id"
                    textField="name"
                    v-bind:options="list.subDistricts"
                    v-model="form.user.subdistrictId"
                    v-on:onDataChange="handleChangeSubDistrict"
                    isRequired
                    ref="subdistrictId"
                    :isValidate="$v.form.user.subdistrictId.$error"
                    :v="$v.form.user.subdistrictId"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.user.zipcode"
                    textFloat="รหัสไปรษณีย์"
                    placeholder="รหัสไปรษณีย์"
                    type="text"
                    name="zipCode"
                    disabled
                    isRequired
                    :isValidate="$v.form.user.zipcode.$error"
                    :v="$v.form.user.zipcode"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <UploadFile
                    textFloat="หนังสือรับรองบริษัท"
                    placeholder="กรุณาเลือกไฟล์"
                    text="กรุณาอัพโหลดเฉพาะไฟล์ .pdf แล้วต้องมีขนาดน้อยกว่า 10 MB"
                    format="pdf"
                    :fileName="form.user.registrationCertUrl"
                    v-model="form.user.registrationCertUrl"
                    v-on:onFileChange="handleChangeRegistrationCert"
                    name="registrationCertUrl"
                    typeFile="application/pdf"
                    :downloadPath="form.user.registrationCertUrl"
                    :isObject="true"
                    :isValidate="$v.form.user.registrationCertUrl.$error"
                    :v="$v.form.user.registrationCertUrl"
                    @delete="form.user.registrationCertUrl = ''"
                  />
                </b-col>
                <b-col md="6">
                  <UploadFile
                    textFloat="เอกสารภพ.20"
                    placeholder="กรุณาเลือกไฟล์"
                    text="กรุณาอัพโหลดเฉพาะไฟล์ .pdf แล้วต้องมีขนาดน้อยกว่า 10 MB"
                    format="pdf"
                    :fileName="form.user.businessTaxUrl"
                    v-model="form.user.businessTaxUrl"
                    v-on:onFileChange="handleChangeBusinessTax"
                    name="businessTaxUrl"
                    typeFile="application/pdf"
                    :downloadPath="form.user.businessTaxUrl"
                    :isObject="true"
                    :isValidate="$v.form.user.businessTaxUrl.$error"
                    :v="$v.form.user.businessTaxUrl"
                    @delete="form.user.businessTaxUrl = ''"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <UploadFile
                    textFloat="ภาพหน้าร้าน"
                    placeholder="กรุณาเลือกไฟล์"
                    text="กรุณาอัพโหลดเฉพาะไฟล์ .png, .jpg แล้วต้องมีขนาดน้อยกว่า 10 MB"
                    format="image"
                    :fileName="form.user.storeImageUrl"
                    v-model="form.user.storeImageUrl"
                    v-on:onFileChange="handleChangeStoreImage"
                    name="storeImageUrl"
                    isRequired
                    typeFile="image/jpeg, image/png"
                    :downloadPath="form.user.storeImageUrl"
                    :isObject="true"
                    :isValidate="$v.form.user.storeImageUrl.$error"
                    :v="$v.form.user.storeImageUrl"
                    @delete="form.user.storeImageUrl = ''"
                  />
                  <div class="d-flex" v-if="form.user.storeImageUrl">
                    <div
                      class="img-slip mb-3"
                      v-bind:style="{
                        backgroundImage: 'url(' + form.user.storeImageUrl + ')',
                      }"
                    ></div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <p class="font-weight-bold">ข้อมูลผู้ติดต่อ</p>
                </b-col>
              </b-row>
              <b-row
                v-for="(item, index) in form.user.userContact"
                :key="index"
              >
                <b-col cols="6">
                  <InputText
                    v-model="item.fullname"
                    :textFloat="`ชื่อผู้รับคนที่ ${index + 1}`"
                    :placeholder="`ชื่อผู้รับคนที่ ${index + 1}`"
                    type="text"
                    name="fullname"
                    isRequired
                    v-bind:isValidate="
                      $v.form.user.userContact.$each.$iter[index].fullname
                        .$error
                    "
                    :v="$v.form.user.userContact.$each.$iter[index].fullname"
                  />
                </b-col>
                <b-col cols="5">
                  <InputText
                    v-model="item.telephone"
                    textFloat="เบอร์โทรศัพท์"
                    placeholder="เบอร์โทรศัพท์"
                    type="text"
                    name="telephone"
                    isRequired
                    v-bind:isValidate="
                      $v.form.user.userContact.$each.$iter[index].telephone
                        .$error
                    "
                    :v="$v.form.user.userContact.$each.$iter[index].telephone"
                  />
                </b-col>
                <b-col cols="1" class="text-center px-0 pt-2">
                  <b-button
                    variant="link"
                    class="btn-link-main p-0 mt-4"
                    @click="deleteContact(item, index)"
                  >
                    <font-awesome-icon icon="trash-alt" class="text-danger" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="text-center" v-if="form.user.userContact.length == 0">
                <b-col>
                  <span class="text-danger">
                    ไม่มีรายการผู้ติดต่อ
                  </span>
                </b-col>
              </b-row>
              <b-row
                class="my-3 text-center"
                v-if="form.user.userContact.length < 5"
              >
                <b-col>
                  <b-button
                    class="btn btn-main btn-details-set ml-md-2 text-uppercase"
                    type="button"
                    @click="addContact"
                    >เพิ่ม</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col md="6"></b-col>
                <b-col md="6" class="text-sm-right">
                  <button
                    type="button"
                    @click="submit"
                    :disabled="isDisable"
                    class="btn btn-main btn-details-set ml-md-2 text-uppercase"
                  >
                    บันทึก
                  </button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row class="my-3 mx-0">
          <b-col md="6" class="pl-md-0 pr-md-1 px-0 mb-2 mb-md-0">
            <div class="bg-white p-3 h-100">
              <b-row>
                <b-col cols="8">
                  <p class="font-weight-bold">ที่อยู่ในการจัดส่ง</p>
                </b-col>
                <b-col cols="4" class="text-right"> </b-col>
              </b-row>

              <template v-if="form.user.shippingAddress.id">
                <div class="address-box">
                  <p>
                    {{ form.user.shippingAddress.firstname }}
                    {{ form.user.shippingAddress.lastname }}
                  </p>
                  <p>
                    {{ form.user.shippingAddress.address }}
                    {{ form.user.shippingAddress.building }}
                    {{ form.user.shippingAddress.alley }}
                    {{ form.user.shippingAddress.road }}
                    {{ form.user.shippingAddress.subdistrict }}
                    {{ form.user.shippingAddress.district }}
                    {{ form.user.shippingAddress.province }}
                    {{ form.user.shippingAddress.zipCode }}
                  </p>
                  <p class="mt-3">
                    โทร : {{ form.user.shippingAddress.telephone }}
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="text-danger">ไม่มีที่อยู่ในการจัดส่ง</div>
              </template>
            </div>
          </b-col>
          <b-col md="6" class="pr-md-0 pl-md-1 px-0">
            <div class="bg-white p-3 h-100">
              <b-row>
                <b-col cols="8">
                  <p class="font-weight-bold">ที่อยู่ในการออกใบเสร็จ</p>
                </b-col>
                <b-col cols="4" class="text-right"> </b-col>
              </b-row>
              <template v-if="form.user.billingAddress.id">
                <div class="address-box">
                  <p>
                    {{ form.user.billingAddress.firstname }}
                    {{ form.user.billingAddress.lastname }}
                  </p>
                  <p>
                    {{ form.user.billingAddress.address }}
                    {{ form.user.billingAddress.building }}
                    {{ form.user.billingAddress.alley }}
                    {{ form.user.billingAddress.road }}
                    {{ form.user.billingAddress.subdistrict }}
                    {{ form.user.billingAddress.district }}
                    {{ form.user.billingAddress.province }}
                    {{ form.user.billingAddress.zipCode }}
                  </p>
                  <div class="mt-3">
                    <div v-if="form.user.billingAddress.isNormalPerson === 0">
                      <p>
                        ชื่อบริษัท :
                        {{ form.user.billingAddress.businessName || "-" }}
                      </p>
                      <p>
                        เลขที่สาขา :
                        {{ form.user.billingAddress.branchNo || "-" }}
                      </p>
                      <p>
                        ชื่อสาขา :
                        {{ form.user.billingAddress.branchName || "-" }}
                      </p>
                    </div>
                    <p>
                      เลขประจำตัวผู้เสียภาษี :
                      {{ form.user.billingAddress.taxInvoice || "-" }}
                    </p>
                    <p>
                      อีเมลในการจัดส่งใบกำกับภาษีอิเล็กทรอนิกส์ :
                      {{ form.user.billingAddress.taxInvoiceEmail || "-" }}
                    </p>
                  </div>
                  <p>โทร : {{ form.user.billingAddress.telephone }}</p>
                </div>
              </template>
              <template v-else>
                <div class="text-danger">ไม่ขอรับใบกำกับภาษี</div>
              </template>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row class="nomargin-mobile py-2">
          <b-col md="4" class="mb-2 mb-md-0"
            ><div class="bg-white text-center py-2 h-100 br-10">
              <font-awesome-icon icon="medal" class="stars mr-2" />ระดับ
              <template v-if="form.user.tier == 'Silver'">
                <img
                  src="@/assets/images/Silver.png"
                  alt="silver image icon"
                  class="ml-2 tier-icon"
                />
              </template>
              <template v-else>
                <span
                  class="text-warning font-weight-bold f-20 text-uppercase ml-3"
                  >{{ form.user.tier }}</span
                >
              </template>
            </div>
          </b-col>
          <b-col md="4" class="mb-2 mb-md-0"
            ><div class="bg-white text-center py-2 h-100 br-10">
              <font-awesome-icon icon="star" class="stars" /> คะแนน
              <span class="text-primary f-20 font-weight-bold ml-3">{{
                form.user.point | numeral("0,0")
              }}</span>
            </div></b-col
          >
          <b-col md="4"
            ><div class="bg-white text-center py-2 h-100 br-10">
              <b-row class="mt-1">
                <b-col cols="5"
                  ><div class="mb-2 mb-sm-0 font-weight-bold">
                    สถานะบัญชี :
                  </div></b-col
                >
                <b-col cols="7">
                  <div
                    :class="[
                      form.bankAccount.bankApproveStatusId == 1
                        ? 'text-success'
                        : 'text-dark',
                    ]"
                  >
                    {{ form.bankAccount.bankApproveStatus }}
                  </div>
                </b-col>
              </b-row>
            </div></b-col
          >
        </b-row> -->

        <b-row class="mx-3 mx-sm-0 mt-3 mt-md-1">
          <b-col
            xl="6"
            offset-xl="6"
            class="text-right d-flex px-0 justify-content-end"
          >
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="เลขที่รายการ"
                @keyup="handleSearch"
                v-model="filter.search"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
            <b-button v-b-toggle.sidebar-1 class="btn-filter">
              <font-awesome-icon
                icon="filter"
                title="filter-btn"
                class="text-white mr-0 mr-sm-1"
              />
              <span class="d-none d-sm-inline"
                >ค้นหา ({{
                  countStatus +
                  countPaymentChannel +
                  countStartdate +
                  countEnddate
                }})</span
              >
            </b-button>
          </b-col>
        </b-row>

        <b-sidebar
          id="sidebar-1"
          title="ค้นหาแบบละเอียด"
          backdrop
          shadow
          backdrop-variant="dark"
          right
          ref="filterSidebar"
        >
          <div class="px-3 py-2">
            <div class="text-right">
              <button
                type="button"
                class="btn btn-link px-0"
                @click="onClearFilter()"
              >
                ล้างค่า
              </button>
            </div>

            <InputSelect
              class="mb-4"
              title="ช่องทางการชำระเงิน"
              name="payment"
              v-bind:options="paymentChannelList"
              valueField="id"
              textField="name"
              v-model="paymentChanelSelected"
              @onDataChange="handleChangePaymentChannel"
            />

            <InputSelect
              class="mb-4"
              title="สถานะรายการขาย"
              name="status"
              v-bind:options="statusList"
              valueField="id"
              textField="name"
              v-model="statusSelected"
              @onDataChange="handleChangestatus"
            />

            <label class="label-text">วันเริ่มต้น</label>
            <datetime
              placeholder="กรุณาเลือกวันที่"
              class="date-picker"
              format="dd MMM yyyy"
              v-model="filter.StartDate"
            ></datetime>

            <label class="label-text mt-3">วันสิ้นสุด</label>
            <datetime
              placeholder="กรุณาเลือกวันที่"
              class="date-picker"
              format="dd MMM yyyy"
              v-model="filter.EndDate"
            ></datetime>

            <div class="text-center">
              <p class="text-danger" v-if="errorDate">
                วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
              </p>
            </div>

            <div class="text-center mt-4">
              <button
                type="button"
                class="btn btn-purple button"
                @click="getDataByStatus"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </b-sidebar>

        <b-row class="mt-4 mb-2 nomargin-mobile">
          <b-col xl="12" class="mb-2 mb-xl-0">
            <div class="bg-blue p-3">
              <b-row>
                <b-col>
                  <p class="font-weight-bold m-0">ยอดทั้งหมด</p>
                  <p class="m-0 f-14">
                    ตั้งแต่ {{ new Date() | moment("MMMM YYYY") }}
                  </p>
                </b-col>
                <b-col
                  class="mb-2 mb-sm-0 f-16 font-weight-bold text-white py-2 text-right"
                  >฿ {{ form.sales | numeral("0,0.00") }}</b-col
                >
              </b-row>
            </div>
          </b-col>
          <!-- <b-col xl="4" class="mb-2 mb-xl-0">
            <div class="bg-blue p-3 h-100">
              <b-row class="h-100">
                <b-col class="m-auto">
                  <p class="font-weight-bold m-0">เงินคืนที่ได้รับ</p>
                </b-col>
                <b-col
                  class="f-16 font-weight-bold text-white text-right m-auto"
                >
                  ฿ {{ form.cashBack | numeral("0,0.00") }}</b-col
                >
              </b-row>
            </div>
          </b-col> -->
          <!-- <b-col xl="6" class="mb-2 mb-xl-0">
            <div class="bg-blue p-3 h-100">
              <b-row class="h-100">
                <b-col class="m-auto">
                  <p class="font-weight-bold m-0">จำนวนออร์เดอร์</p>
                </b-col>
                <b-col
                  class="f-16 font-weight-bold text-white text-right m-auto"
                  >{{
                    form.user.qtyOrdersCompleted | numeral("0,0")
                  }}
                  รายการ</b-col
                >
              </b-row>
            </div>
          </b-col> -->
        </b-row>

        <div class="mt-3 bg-white mx-3 mx-sm-0 pb-3">
          <b-row class="no-gutters">
            <b-col>
              <b-table
                responsive
                class="text-center table-list"
                striped
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="ไม่พบข้อมูล"
              >
                <template v-slot:cell(paymentType)="data">
                  <p class="mb-0 nobreak" v-if="data.item.paymentType != null">
                    {{ data.item.paymentType }}
                  </p>
                  <p class="m-0" v-else>-</p>
                </template>
                <template v-slot:cell(grandTotal)="data">
                  <p class="m-0">
                    ฿ {{ data.item.grandTotal | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(cashback)="data">
                  <p class="m-0">
                    ฿ {{ data.item.cashback | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(sellerfee)="data">
                  <p class="m-0">
                    ฿ {{ data.item.sellerfee | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(sellerfeeVat)="data">
                  <p class="m-0">
                    ฿ {{ data.item.sellerfeeVat | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(createdTime)="data">
                  <span>{{
                    new Date(data.item.createdTime) | moment($formatDate)
                  }}</span>
                </template>
                <template v-slot:cell(point)="data">
                  <p class="m-0">{{ data.item.point | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(qty)="data">
                  <p class="m-0">{{ data.item.qty | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(orderStatus)="data">
                  <div
                    v-if="
                      data.item.orderStatusId == 10 ||
                      data.item.orderStatusId < 5
                    "
                    class="text-warning"
                  >
                    {{ data.item.orderStatus }}
                  </div>
                  <div
                    v-else-if="
                      data.item.orderStatusId == 5 ||
                      data.item.orderStatusId == 11
                    "
                    class="text-success"
                  >
                    {{ data.item.orderStatus }}
                  </div>
                  <div v-else class="text-danger">
                    {{ data.item.orderStatus }}
                  </div>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="no-gutters px-3 px-sm-0">
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex m-3">
                <b-pagination
                  v-model="filter.pageNo"
                  :total-rows="rows"
                  :per-page="filter.perPage"
                  class="m-md-0"
                  @change="pagination"
                  align="center"
                ></b-pagination>
              </div>

              <b-form-select
                class="mr-sm-3 select-page"
                v-model="filter.perPage"
                @change="hanndleChangePerpage"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>

        <!-- <div class="mt-3 bg-white mx-3 mx-sm-0 p-3">
          <h3 class="mr-sm-4 header-main text-uppercase">รายการคะแนนหมดอายุ</h3>
          <b-row class="no-gutters">
            <b-col>
              <b-table
                responsive
                class="text-center table-list"
                striped
                :fields="fieldsPointExpired"
                :items="expiredPoint.dataList"
                :busy="isBusyPointExpired"
                show-empty
                empty-text="ไม่พบข้อมูล"
              >
                <template v-slot:cell(point)="data">
                  <p class="m-0">{{ data.item.point | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(updatedTime)="data">
                  <span>{{
                    new Date(data.item.updatedTime) | moment($formatDate)
                  }}</span>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="no-gutters px-3 px-sm-0">
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex m-3">
                <b-pagination
                  v-model="expiredPointFilter.pageNo"
                  :total-rows="expiredPoint.count"
                  :per-page="expiredPointFilter.perPage"
                  class="m-md-0"
                  @change="paginationExpiredPoint"
                  align="center"
                ></b-pagination>
              </div>

              <b-form-select
                class="mr-sm-3 select-page"
                v-model="expiredPointFilter.perPage"
                @change="hanndleChangePerpageExpiredPoint"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div> -->

        <!-- <div class="mt-3 bg-white mx-3 mx-sm-0 p-3">
          <h3 class="mr-sm-4 header-main text-uppercase">
            รายการเงินคืนที่ได้รับ
          </h3>
          <b-row class="no-gutters">
            <b-col>
              <b-table
                responsive
                class="text-center table-list"
                striped
                :fields="fieldsCashbackReceived"
                :items="cashbackReceived.dataList"
                :busy="isBusyCashbackReceived"
                show-empty
                empty-text="ไม่พบข้อมูล"
              >
                <template v-slot:cell(cashback)="data">
                  <p class="m-0">{{ data.item.cashback | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(updatedTime)="data">
                  <span>{{
                    new Date(data.item.updatedTime) | moment($formatDate)
                  }}</span>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="no-gutters px-3 px-sm-0">
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex m-3">
                <b-pagination
                  v-model="cashbackReceivedFilter.pageNo"
                  :total-rows="cashbackReceived.count"
                  :per-page="cashbackReceivedFilter.perPage"
                  class="m-md-0"
                  @change="paginationCashbackReceived"
                  align="center"
                ></b-pagination>
              </div>

              <b-form-select
                class="mr-sm-3 select-page"
                v-model="cashbackReceivedFilter.perPage"
                @change="hanndleChangePerpageCashbackReceived"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div> -->
        <b-row class="no-gutters mt-3">
          <b-col sm="6" class="text-center text-sm-left">
            <router-link to="/affiliate">
              <button
                type="button"
                class="btn btn-cancel btn-details-set text-uppercase nowrap"
              >
                ย้อนกลับ
              </button>
            </router-link>
          </b-col>
          <b-col sm="6" class="text-sm-right">
            <button
              type="button"
              class="btn btn-cancel btn-details-set ml-md-3 text-uppercase nowrap"
              @click="$refs['rejectAccountModal'].show()"
              v-if="form.user.statusId == 0"
            >
              ปฏิเสธ
            </button>
            <button
              type="button"
              class="btn btn-main btn-details-set ml-md-3 text-uppercase nowrap"
              @click="sendApproveRejectRequest(1)"
              v-if="form.user.statusId == 0"
            >
              ยอมรับ
            </button></b-col
          >
        </b-row>
      </div>
    </div>

    <b-modal
      id="rejectAccountModal"
      ref="rejectAccountModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <h3 class="font-weight-bold">กรุณาบอกเหตุผลในการปฏิเสธ</h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('rejectAccountModal')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <InputTextArea
                textFloat="เหตุผล"
                placeholder="เหตุผล"
                type="text"
                name="note"
                v-model="note"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="text-center mt-3">
        <b-button
          class="btn btn-main float-right"
          @click="sendApproveRejectRequest(2)"
          :disabled="!note"
          >บันทึก</b-button
        >
        <b-button
          class="btn btn-sub float-left"
          @click="$bvModal.hide('rejectAccountModal')"
          >ปิด</b-button
        >
      </div>
    </b-modal>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertConfirm
      msg="ยืนยันการทำรายการ ?"
      :text="'คุณต้องการที่จะเปลี่ยน Tier ใช่หรือไม่'"
      btnConfirm="ยืนยัน"
      colorBtnConfirm="primary"
      btnCancel="ยกเลิก"
      ref="isModalAlertConfirm"
      @confirm="handleChangeTier"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import UploadFile from "@/components/inputs/UploadFile";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";

import {
  required,
  email,
  minLength,
  maxLength,
  minValue,
  numeric,
  integer,
  requiredIf
} from "vuelidate/lib/validators";

export default {
  name: "AffiliateDetails",
  components: {
    InputText,
    InputSelect,
    ModalAlertError,
    DateTimePicker,
    ModalLoading,
    ModalAlert,
    UploadFile,
    InputTextArea,
    ModalAlertConfirm,
  },
  data() {
    return {
      id: this.$route.params.id,
      fields: [
        {
          key: "orderNo",
          label: "เลขที่รายการ",
          class: "w-100px text-nowrap",
        },
        {
          key: "createdTime",
          label: "วันที่ทำรายการ",
          class: "w-100px text-nowrap",
        },
        {
          key: "paymentType",
          label: "ช่องทางการชำระเงิน",
          class: "w-100px text-nowrap",
        },
        { key: "grandTotal", label: "จำนวนเงิน", class: "w-100px text-nowrap" },
        { key: "qty", label: "จำนวนสินค้า", class: "w-100px text-nowrap" },
        {
          key: "orderStatus",
          label: "สถานะคำสั่งซื้อ",
          class: "w-100px text-nowrap",
        },
        // {
        //   key: "point",
        //   label: "คะแนน",
        //   class: "w-100px text-nowrap",
        // },
        // {
        //   key: "cashback",
        //   label: "เงินคืน",
        //   class: "w-100px text-nowrap",
        // },
        // {
        //   key: "sellerfee",
        //   label: "ค่าธรรมเนียมการขาย",
        //   class: "w-100px text-nowrap",
        // },
        // {
        //   key: "sellerfeeVat",
        //   label: "ค่าธรรมเนียมการขาย (รวมภาษีมูลค่าเพิ่ม)",
        //   class: "w-225px",
        // },
      ],
      fieldsPointExpired: [
        {
          key: "updatedTime",
          label: "วันหมดอายุ",
        },
        {
          key: "point",
          label: "จำนวนคะแนนที่หมดอายุ",
        },
      ],
      fieldsCashbackReceived: [
        {
          key: "updatedTime",
          label: "วันที่ได้รับ",
        },
        {
          key: "cashback",
          label: "จำนวนเงินคืนที่ได้รับ",
        },
      ],
      filter: {
        pageNo: 1,
        perPage: 10,
        status: [],
        StartDate: null,
        EndDate: null,
        PaymentChanel: [],
        search: "",
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      paymentChannelFilter: {
        perPage: 10,
        pageNo: 1,
        search: "",
        status: [],
        sortByOrderOrId: 1,
      },
      form: null,
      isBusy: false,
      rows: 0,
      paymentChannelList: [],
      paymentChanelSelected: 0,
      statusList: [],
      items: [],
      statusSelected: 0,
      modalMessage: "",
      errorDate: false,
      isBusyPointExpired: false,
      expiredPoint: [],
      expiredPointFilter: {
        perPage: 10,
        pageNo: 1,
      },
      isBusyCashbackReceived: false,
      cashbackReceived: [],
      cashbackReceivedFilter: {
        perPage: 10,
        pageNo: 1,
      },
      isDisable: false,
      tier: [],
      list: {
        userTargetType: [],
        userBusinessType: [],
        userRegistrationType: [],
        provinces: [{ id: 0, name: "กรุณาเลือกข้อมูล" }],
        districts: [{ id: 0, name: "กรุณาเลือกข้อมูล" }],
        subDistricts: [{ id: 0, name: "กรุณาเลือกข้อมูล" }],
      },
      note: "",
    };
  },
  validations() {
    return {
      form: {
        user: {
          companyName: {
            required,
          },
          telephone: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          email: { required, email },
          userTargetTypeId: {
            required,
            minLength: minLength(1),
          },
          businessTypeId: {
            required,
            minValue: minValue(1),
          },
          registrationTypeId: {
            required,
            minValue: minValue(1),
          },
          address: {
            required,
          },
          branchName: {
            required: requiredIf(function () {
              return this.form.user.registrationTypeId != 4;
            }), 
          },
          branchNo: {
            required: requiredIf(function () {
              return this.form.user.registrationTypeId != 4;
            }), 
            numeric,
            maxLength: maxLength(5),
            minLength: minLength(5),
          },
          taxId: {
            required,
            integer,
            maxLength: maxLength(13),
            minLength: minLength(13),
          },
          businessName: {
            required,
          },
          provinceId: {
            required,
            minValue: minValue(1),
          },
          districtId: {
            required,
            minValue: minValue(1),
          },
          subdistrictId: {
            required,
            minValue: minValue(1),
          },
          zipcode: { required },
          registrationCertUrl: {},
          businessTaxUrl: {},
          storeImageUrl: { required },
          userContact: {
            $each: {
              fullname: {
                required,
              },
              telephone: { required, numeric, minLength: minLength(10) },
            },
          },
        },
      },
    };
  },
  computed: {
    imageProfile: function () {
      return (
        this.form.user.imageUrl ||
        "https://uat-img.getfin.me/userprofile/214802124820876.png"
      );
    },
    countPaymentChannel: function () {
      var count = 0;
      if (this.filter.PaymentChanel.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStatus: function () {
      var count = 0;
      if (this.filter.status.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStartdate: function () {
      var count = 0;
      if (this.filter.StartDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countEnddate: function () {
      var count = 0;

      if (this.filter.EndDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function () {
    await this.getData();
    await this.getOrder();
    await this.getFilter();
    await this.getProvince();
    await this.getUserTargetType();
    await this.getUserBusinessType();
    await this.getUserRegistrationType();
    // await this.getExpiredPoint();
    // await this.getCashbackReceived();
    if (this.form.user.zipcodeId) {
      let address = {
        provinceId: this.form.user.provinceId,
        districtId: this.form.user.districtId,
        subdistrictId: this.form.user.subdistrictId,
      };
      await this.handleChangeProvince(address.provinceId);
      await this.handleChangeDistrict(address.districtId);
      await this.handleChangeSubDistrict(address.subdistrictId);
    }
    this.$isLoading = true;
  },
  methods: {
    getExpiredPoint: async function () {
      this.isBusyPointExpired = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/affiliate/Profile/ExpiredPoint/${this.id}`,
        null,
        this.$headers,
        this.expiredPointFilter
      );
      if (resData.result == 1) {
        this.expiredPoint = resData.detail.result;
      }
      this.isBusyPointExpired = false;
    },
    getCashbackReceived: async function () {
      this.isBusyCashbackReceived = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Affiliate/Profile/CashbackLog/${this.id}`,
        null,
        this.$headers,
        this.cashbackReceivedFilter
      );
      if (resData.result == 1) {
        this.cashbackReceived = resData.detail.result;
      }
      this.isBusyCashbackReceived = false;
    },
    getData: async function () {
      this.isBusyPointExpired = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Affiliate/Profile/` + this.id,
        null,
        this.$headers,
        null
      );

      if (resData.result == 1) {
        this.form = resData.detail.result;
        this.form.user.birthday = this.$moment(this.form.user.birthday).format(
          "YYYY-MM-DD"
        );
      }

      let tier = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/User/GetTierAll`,
        null,
        this.$headers,
        null
      );

      if (tier.result == 1) {
        let list = [{ id: 0, name: "กรุณาเลือกข้อมูล" }];
        this.tier = list.concat(tier.detail.data);
      }
      this.isBusyPointExpired = false;
    },
    getOrder: async function () {
      this.isBusy = true;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Affiliate/Orders/` + this.id,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;

        this.isBusy = false;
      }
    },
    getFilter: async function () {
      let paymentChannel = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/PaymentChannel/List`,
        null,
        this.$headers,
        this.paymentChannelFilter
      );
      if (paymentChannel.result == 1) {
        let list = [{ id: 0, name: `กรุณาเลือกช่องทางการชำระเงิน` }];
        let datalist = paymentChannel.detail.dataList;
        this.paymentChannelList = list.concat(datalist);
      }

      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/OrderStatusWithCount`,
        null,
        this.$headers,
        null
      );

      if (status.result == 1) {
        let list = [];
        let datalist = status.detail;
        this.statusList = list.concat(datalist);
      }
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.PageNo = 1;
        this.getOrder();
      }
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getOrder();
    },
    getDataByStatus(status) {
      if (
        this.filter.StartDate > this.filter.EndDate &&
        this.filter.EndDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getOrder();
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getOrder();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getOrder();
    },
    paginationExpiredPoint(Page) {
      this.expiredPointFilter.PageNo = Page;
      this.getExpiredPoint();
    },
    hanndleChangePerpageExpiredPoint(value) {
      this.expiredPointFilter.PageNo = 1;
      this.expiredPointFilter.PerPage = value;
      this.getExpiredPoint();
    },
    paginationCashbackReceived(Page) {
      this.cashbackReceivedFilter.PageNo = Page;
      this.getCashbackReceived();
    },
    hanndleChangePerpageCashbackReceived(value) {
      this.cashbackReceivedFilter.PageNo = 1;
      this.cashbackReceivedFilter.PerPage = value;
      this.getCashbackReceived();
    },
    handleChangePaymentChannel: async function (value) {
      this.filter.PaymentChanel = [];
      if (value != 0) {
        this.filter.PaymentChanel.push(value);
      }
    },
    handleChangeTierType: async function (value) {
      this.form.user.tierId = value;
      this.$refs.isModalAlertConfirm.show();
    },
    handleChangeTier: async function () {
      this.$refs.isModalAlertConfirm.hide();
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/User/UpdateTier`,
        null,
        this.$headers,
        {
          userId: this.id,
          tierId: this.form.user.tierId,
        }
      );

      this.modalMessage = data.message;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    handleChangestatus: async function (value) {
      this.filter.status = [];
      if (value != 0) {
        this.filter.status.push(value);
      }
    },
    onClearFilter() {
      this.filter.PageNo = 1;
      this.filter.StartDate = "";
      this.filter.EndDate = "";
      this.filter.PaymentChanel = [];
      this.filter.status = [];
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getOrder();
    },
    onChangeEnableAffiliate: async function (value) {
      let body = {
        Enabled: value,
        UserId: this.id,
      };

      let resData = await this.$callApi(
        "patch",
        `${this.$baseUrl}/api/Affiliate/Profile/Enbled`,
        null,
        this.$headers,
        body
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        //this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    onChangeBirthday: async function (val) {
      this.form.user.birthday = val;
    },
    submit: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/User/${this.id}`,
        null,
        this.$headers,
        this.form.user
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
        this.$v.form.$reset();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    resetPassword: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/ResetPassword/${this.id}`,
        null,
        this.$headers
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    async getUserTargetType() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/UserTargetType`,
        null,
        this.$headers
      );
      if (resData.result == 1) {
        this.list.userTargetType = resData.detail;
      }
    },
    async getUserBusinessType() {
      let list = [{ businessTypeId: 0, name: "กรุณาเลือกข้อมูล" }];
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/UserBusinessType`,
        null,
        this.$headers
      );
      if (resData.result == 1) {
        this.list.userBusinessType = list.concat(resData.detail);
      }
    },
    async getUserRegistrationType() {
      let list = [{ registrationTypeId: 0, name: "กรุณาเลือกข้อมูล" }];
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/UserRegistrationType`,
        null,
        this.$headers
      );
      if (resData.result == 1) {
        this.list.userRegistrationType = list.concat(resData.detail);
      }
    },
    async getProvince() {
      let list = [{ id: 0, name: "กรุณาเลือกข้อมูล" }];
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Address/Provinces`,
        null,
        this.$headers
      );
      if (resData.result == 1) {
        this.list.provinces = list.concat(resData.detail);
      }
    },
    async handleChangeProvince(id) {
      this.form.user.provinceId = id;
      if (id) {
        this.form.user.districtId = 0;
        this.form.user.subdistrictId = 0;
        this.form.user.zipcode = "";
        this.form.user.zipcodeId = 0;
      }
      if (this.list.provinces.length == 0) await this.getProvince();
      let districts = [];
      let list = [{ id: 0, name: "กรุณาเลือกข้อมูล" }];
      if (this.form.user.provinceId) {
        let resData = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/Address/District/${this.form.user.provinceId}`,
          null,
          this.$headers
        );
        if (resData.result == 1) {
          districts = list.concat(resData.detail);
        }
      }
      this.list.districts = districts;
    },
    async handleChangeDistrict(id) {
      this.form.user.districtId = id;
      if (id) {
        this.form.user.subdistrictId = 0;
        this.form.user.zipcode = "";
        this.form.user.zipcodeId = 0;
      }

      let subDistricts = [];
      let list = [{ id: 0, name: "กรุณาเลือกข้อมูล" }];
      if (this.form.user.districtId) {
        let resData = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/Address/SubDistrict/${this.form.user.districtId}`,
          null,
          this.$headers
        );
        if (resData.result == 1) {
          subDistricts = list.concat(resData.detail);
        }
      }
      this.list.subDistricts = subDistricts;
    },
    async handleChangeSubDistrict(id) {
      this.form.user.subdistrictId = id;

      if (this.form.user.subdistrictId) {
        let resData = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/Address/Zipcode/${this.form.user.subdistrictId}/${this.form.user.districtId}/${this.form.user.provinceId}`,
          null,
          this.$headers
        );
        if (resData.result == 1) {
          this.form.user.zipcodeId = resData.detail.id;
          this.form.user.zipcode = resData.detail.name;
        }
      }
    },
    handleChangeTargetType: async function (value) {
      this.form.user.targetTypeId = value;
    },
    handleChangeBusinessType: async function (value) {
      this.form.user.businessTypeId = value;
    },
    handleChangeRegistrationType: async function (value) {
      this.form.user.registrationTypeId = value;
    },
    handleChangeRegistrationCert: async function (img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        let body = {
          base64: reader.result,
        };
        let resData = await this.$callApi(
          "put",
          `${this.$baseUrl}/api/UserRegistrationCert`,
          null,
          this.$headers,
          body
        );
        if (resData.result == 1) {
          this.form.user.registrationCertUrl = resData.detail.result;
        }
      };
    },
    handleChangeBusinessTax: async function (img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        let body = {
          base64: reader.result,
        };
        let resData = await this.$callApi(
          "put",
          `${this.$baseUrl}/api/UserBusinessTax`,
          null,
          this.$headers,
          body
        );
        if (resData.result == 1) {
          this.form.user.businessTaxUrl = resData.detail.result;
        }
      };
    },
    handleChangeStoreImage: async function (img) {
      let reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        let body = {
          base64: reader.result,
        };
        let resData = await this.$callApi(
          "put",
          `${this.$baseUrl}/api/UserStoreImage`,
          null,
          this.$headers,
          body
        );
        if (resData.result == 1) {
          this.form.user.storeImageUrl = resData.detail.result;
        }
      };
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async addContact() {
      this.form.user.userContact.push({ fullname: "", telephone: "" });
    },
    async deleteContact(item, index) {
      this.form.user.userContact.splice(index, 1);
    },
    sendApproveRejectRequest: async function (status) {
      this.$refs.modalLoading.show();
      let request = {
        UserGUID: this.id,
        note: this.note,
        StatusId: status, // 1 approve // 2 reject
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Store/Approve`,
        null,
        this.$headers,
        request
      );

      this.modalMessage = data.message;
      this.$refs.modalLoading.hide();
      this.$refs.rejectAccountModal.hide();

      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.tier-icon {
  width: 30%;
}
.stars {
  color: #ffb300;
}

.br-10 {
  border-radius: 10px;
}

.bg-blue {
  background-color: #1085ff;
  color: white !important;
  border-radius: 15px;
}

.affiliate-img {
  width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  /* border-radius: 50%;
    border: 2px solid red; */
}

.seller-status {
  padding: 0 10px;
  border-radius: 10px;
  white-space: nowrap;
}

.status-yellow {
  background-color: #fed378;
}

.status-green {
  background-color: #2eb85c;
}

.status-red {
  background-color: #ff0000;
}
.address-box p {
  margin: 0;
}

.address-box {
  line-height: 25px;
}
.img-slip {
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px dashed #aeaeae;
}
@media (max-width: 1400px) {
  .affiliate-img {
    width: 100%;
    padding-bottom: 100%;
  }
}

@media (max-width: 600px) {
  .affiliate-img {
    width: 50%;
    padding-bottom: 50%;
  }
}
</style>
